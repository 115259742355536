<template>
  <v-container>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <h1>Withdraw</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-row>
      <v-col cols="12" md="6">
        <v-form ref="transferForm" @submit.prevent="validate()">
          <v-card :loading="loadingTransfer" flat>
            <v-container>
              <v-card-title>Withdraw Fund</v-card-title>
              <v-card-text>
                <p>
                  Your currently have
                  <b class="primary--text">
                    {{ userCurrency }} {{ wallet.balance || 0 }}
                  </b>
                  in your wallet.
                </p>
                <p class="text-h6">Pick a withdraw Method</p>
                <v-item-group mandatory>
                  <v-row>
                    <v-col cols="6" sm="4" md="4">
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="active ? 'amber' : ''"
                          class="d-flex align-center"
                          height="125"
                          @click="toggle"
                        >
                          <v-scroll-y-transition>
                            <div class="text-center mx-auto">
                              <v-icon size="35" color="green">mdi-bank</v-icon>
                              <div
                                style="font-size: 14px"
                                :class="`flex-grow-1 subtitle-2 mt-2 ${
                                  active ? 'white--text' : 'grey--text'
                                } text-center`"
                              >
                                Withdraw
                              </div>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>

                    <v-col v-if="showDataAndAirtime" cols="6" sm="4" md="4">
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="active ? 'red' : ''"
                          class="d-flex align-center"
                          height="125"
                          to="/user/products/bills/airtime/"
                          @click="toggle"
                        >
                          <v-scroll-y-transition>
                            <div class="mx-auto text-center">
                              <v-icon size="35" color="indigo"
                                >mdi-cellphone</v-icon
                              >
                              <div
                                style="font-size: 14px"
                                :class="`flex-grow-1 subtitle-2 mt-2 ${
                                  active ? 'white--text' : 'grey--text'
                                } text-center`"
                              >
                                Buy Airtime
                              </div>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>

                    <v-col v-if="showDataAndAirtime" cols="6" sm="4" md="4">
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="active ? 'indigo' : ''"
                          class="d-flex align-center"
                          height="125"
                          to="/user/products/bills/internet-data/"
                          @click="toggle"
                        >
                          <v-scroll-y-transition>
                            <div class="mx-auto text-center">
                              <v-icon size="35" color="red">mdi-wifi</v-icon>
                              <div
                                style="font-size: 14px"
                                :class="`flex-grow-1 subtitle-2 mt-2 ${
                                  active ? 'white--text' : 'grey--text'
                                } text-center`"
                              >
                                Buy Data
                              </div>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>

                <v-select
                  class="mt-5"
                  filled
                  :loading="withdrawalMethods.length == 0"
                  :items="withdrawalMethods"
                  hint="Select withdraw method"
                  item-text="name"
                  item-value="_id"
                  v-model="method"
                  label="Withdrawal Method"
                ></v-select>

                <div v-if="!loadingBanks && bankAccounts.length === 0">
                  <p class="font-weight-bold">
                    You don't have a bank account set up on your account,
                    <router-link class="accent--text" to="/user/settings"
                      >click here</router-link
                    >
                    to add a bank account to withdraw to
                  </p>
                </div>

                <div v-else>
                  <v-select
                    filled
                    v-if="selectedMethod === 'Bank Transfer'"
                    label="Select Bank"
                    :items="bankAccounts"
                    item-value="_id"
                    item-text="display"
                    v-model="selectedBank"
                    hint="Select your preferred bank to withdraw to"
                  >
                  </v-select>
                  <v-text-field
                    filled
                    :disabled="loadingTransfer"
                    number
                    v-model="amount"
                    type="number"
                    :rules="[
                      (v) =>
                        (v && v <= wallet.balance) ||
                        'You don\'t have that much in your wallet',
                      (v) =>
                        v > minAndMax.min ||
                        `Minimum amount you can withdraw ${minAndMax.min}`,
                      (v) =>
                        v < minAndMax.max ||
                        `Maximum amount you can withdraw is ${minAndMax.max}`
                    ]"
                    label="Amount"
                    hint="Amount to Transfer to user"
                    persistent-hint
                  ></v-text-field>
                  <v-text-field
                    v-if="user.authenticationType === 'otp'"
                    v-model="otp"
                    filled
                    label="OTP"
                    hint="An OTP  will be sent to your registered email to approve this transaction"
                    persistent-hint
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        :loading="sendingOtp"
                        @click="generateOtp"
                        color="primary"
                        dark
                      >
                        Request OTP
                      </v-btn>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-if="user.authenticationType === 'pin'"
                    v-model="transactionPin"
                    filled
                    label="PIN"
                    hint="Enter your PIN"
                    persistent-hint
                  >
                  </v-text-field>
                  <div class="">
                    <p class="mb-2 text-sm font-semibold">
                      Withdrawal fee applies
                      <v-tooltip max-width="250" right>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="border p-1 text-gray-400 text-xs rounded-full border-gray-400"
                            v-bind="attrs"
                            v-on="on"
                          >
                            ?
                          </span>
                        </template>
                        <small style="font-size: 10px; line-height: 2px">
                          Withdrawal fee: To ensure that the usual hassle/delays
                          that afters day to day online transfers do not delay
                          you, we engaged the services of some Banking Partners
                          to speed up Withdrawals, this fee servers as a premium
                          for this service
                        </small>
                      </v-tooltip>
                    </p>
                    <p class="mb-0">
                      You will be charged
                      <span class="primary--text"
                        >{{ userCurrency }} {{ withdrawalCharge }}</span
                      >
                    </p>
                    <p class="mb-3">
                      You will receive
                      <span class="primary--text"
                        >{{ userCurrency }}
                        {{
                          amount === '' ? 0 : Number(amount) - withdrawalCharge
                        }}</span
                      >
                    </p>
                  </div>
                  <p>
                    After this transfer is approved
                    <b class="primary--text">
                      {{ userCurrency }} {{ amount }}
                    </b>
                    will be removed from your wallet and you will have
                    <b class="primary--text"
                      >{{ userCurrency }}
                      {{ Number(wallet.balance) - Number(amount) }}</b
                    >
                    left in your wallet
                  </p>
                  <v-btn
                    block
                    type="submit"
                    :loading="loadingTransfer"
                    large
                    color="primary"
                    :disabled="
                      amountToRemove <= 0 ||
                      amount < minAndMax.min ||
                      amount > minAndMax.max
                    "
                  >
                    Withdraw
                  </v-btn>
                </div>
              </v-card-text>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-toolbar flat
            ><v-tabs v-model="logsTab">
              <v-tab> Approved </v-tab>
              <v-tab>
                <span v-if="pendingTransfers.length === 0"> Pending </span>
                <v-badge v-else color="pink" :content="pendingTransfers.length">
                  Pending
                </v-badge></v-tab
              >
              <v-tab> Declined</v-tab>
            </v-tabs></v-toolbar
          >
          <v-sheet>
            <v-tabs-items v-model="logsTab">
              <v-tab-item>
                <v-data-table
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  }"
                  item-key="_id"
                  :items="approvedTransfers"
                  :headers="headers"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span
                      >{{ item.amount }} <small>{{ userCurrency }}</small>
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No approved Transfer</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  }"
                  item-key="_id"
                  :items="pendingTransfers"
                  :headers="headers"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span
                      >{{ item.amount }} <small>{{ userCurrency }}</small>
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No pending Transfer</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  }"
                  item-key="_id"
                  :items="declinedTransfers"
                  :headers="headers"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span
                      >{{ item.amount }} <small>{{ userCurrency }}</small>
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No declined Transfer</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import walletApi from '../../../api/users/wallet'
import withdrawApi from '../../../api/users/withdraw'
import dataApi from '../../../api/systemData'
import bankApi from '../../../api/users/bankAccount'
import misc from '../../../api/users/misc'
import { COUNTRIES } from '../../../utils/countryHelpers'

export default {
  data() {
    return {
      logsTab: 1,
      amount: 0,
      loading: false,
      method: '',
      selectedBank: '',
      otp: '',
      loadingTransfer: false,
      bankAccounts: [],
      transactionPin: '',
      withdrawalMethods: [],
      usePin: false,
      sendingOtp: false,
      headers: [
        {
          value: 'transaction_id',
          text: 'Tranx No',
          sortable: false
        },
        {
          value: 'amount',
          text: 'Amount'
        },
        {
          value: 'method.name',
          text: 'Gateway'
        },
        {
          value: 'charge',
          text: 'Charge'
        }
      ],
      transfers: [],
      loadingBanks: false
    }
  },
  computed: {
    showDataAndAirtime() {
      return this.userCountry.toLowerCase() === COUNTRIES.NG.toLowerCase()
    },
    selectedMethod() {
      return this.method
        ? this.withdrawalMethods.find((m) => m._id === this.method).name
        : ''
    },
    withdrawalCharge() {
      if (Object.keys(this.minAndMax).length === 0) return 0
      return Math.ceil(
        (this.minAndMax.percent / 100) * this.amount + this.minAndMax.fixed
      )
    },
    amountToRemove() {
      if (Object.keys(this.minAndMax).length === 0) return 0
      return Math.ceil(Number(this.amount) - this.withdrawalCharge)
    },
    minAndMax() {
      if (this.method === '') return {}
      const method = this.withdrawalMethods.find((m) => m._id === this.method)

      return {
        min: Number(
          Number(
            method.withdraw_min /
              this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
          ).toFixed(2)
        ),
        max: Number(
          Number(
            method.withdraw_max /
              this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
          ).toFixed(2)
        ),
        percent: method.percent,
        fixed: Number(
          method.fix /
            this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
        ).toFixed(2)
      }
    },
    pendingTransfers() {
      if (this.transfers === []) return []
      return this.transfers.filter((transfer) => transfer.status === 'pending')
    },
    approvedTransfers() {
      if (this.transfers === []) return []
      return this.transfers.filter((transfer) => transfer.status === 'approved')
    },
    declinedTransfers() {
      if (this.transfers === []) return []
      return this.transfers.filter((transfer) => transfer.status === 'declined')
    },
    ...mapState({
      wallet: (state) => state.user.wallet,
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    })
  },
  created() {
    this.getWithdraws()
    this.getWithdrawalMethods()
    this.getBanks()
    this.$store.dispatch('user/getCountryCurrency')
  },
  methods: {
    async getBanks() {
      this.loadingBanks = true
      const res = await bankApi.profile().getBankAccounts()
      this.bankAccounts = res.data.data.map(d => ({
        ...d,
        display: `${d?.bank_name} - ${d?.account_number}`
      }))
      this.loadingBanks = false
    },
    validate() {
      if (this.$refs.transferForm.validate()) this.makeWithdrawal()
    },
    async getWithdrawalMethods() {
      const res = await dataApi
        .data()
        .getData('user', { type: 'withdrawmethod' })
      this.withdrawalMethods = res.data.data.data.filter((m) => m.status)
    },
    async getWithdraws() {
      this.loading = true
      const res = await withdrawApi
        .withdraw()
        .getAll(['user', 'wallet', 'method'])
      this.transfers = res.data.data.data
      this.loading = false
    },
    async makeWithdrawal() {
      this.loadingTransfer = true

      const payload = {
        amount: this.amount,
        withdrawMethodId: this.method,
        bankId: this.selectedBank
      }

      if (this.user.authenticationType === 'otp') {
        payload.otp = this.otp
      }

      if (this.user.authenticationType === 'pin') {
        payload.transactionPin = this.transactionPin
      }

      const res = await walletApi.data().withdrawFromWallet(payload)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransfer = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Transfer request sent successfully',
        status: true
      })
      this.loadingTransfer = false
      this.amount = ''
      this.username = ''
      this.pin = ''
      this.getWithdraws()
      this.$router.push('/user/dashboard')
    },
    async generateOtp() {
      this.sendingOtp = true
      const res = await misc.misc().generateOtp('withdrawal')
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message || res.internalError.message,
          error: true,
          status: true
        })
        this.sendingOtp = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'OTP sent successfully, kindly check your email',
        status: true
      })
      this.sendingOtp = false
    }
  }
}
</script>

<style></style>
