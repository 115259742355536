import axios from 'axios'
import Store from '../../store'
import {
  errorHandler,
  successHandler,
  generateNoOptionsQuery
} from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/withdraw-logs`

export default {
  withdraw() {
    return {
      getAll: async (populate) => {
        const endpoint = `${URL}${generateNoOptionsQuery(populate)}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      get: async (logId) => {
        const endpoint = `${URL}/${logId}`

        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
